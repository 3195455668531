import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './quasar'

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

firebase.initializeApp({
	apiKey: "AIzaSyDMGomB8lCmGv7GiTXNPSDc_Ya9It22kB4",
	authDomain: "alpha.crewtracks.com",
	projectId: "internal-test-app-distribution",
	storageBucket: "internal-test-app-distribution.appspot.com",
	messagingSenderId: "1049158421367",
	appId: "1:1049158421367:web:d5ceee9693faf257b02f72"
});

if (location.hostname === 'localhost') {
	firebase.firestore().useEmulator('localhost', 6321);
	firebase.functions().useEmulator('localhost', 6320);
}

Vue.config.productionTip = false;

function checkValidEmail(email: string) {
	return email.endsWith("@crewtracks.com") ||
		email.endsWith("@gurutechnologies.net");
}

function addUserToFirebase(user: any) {
	if (!user) {
		return;
	}
	firebase.firestore()
		.collection("users")
		.doc(user.id)
		.set(user.providerData[0], { merge: true });
}

let app: Vue;
firebase.auth().onAuthStateChanged(() => {
	const user = firebase.auth().currentUser;
	if (user != null && !checkValidEmail(user?.email || '')) {
		firebase.auth().signOut();
	}
	if (!app) {
		app = new Vue({
			router,
			store: store,
			render: h => h(App)
		}).$mount('#app');
	}
	if (user) {
		(user as any)['id'] = user?.providerData[0]?.uid;
	}
	store.commit('setUser', user);
	addUserToFirebase(user);
})

