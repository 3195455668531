







































import Vue from 'vue';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css'

export default Vue.extend({
	name: 'Home',
	data() {
		return {
			fbuiConfig: {
				callbacks: {
					signInSuccessWithAuthResult: (_a: any, _b: any) => false,
				},
				signInOptions: [
					firebase.auth.GoogleAuthProvider.PROVIDER_ID,
				],
			},
		}
	},
	mounted: function() {
		const fbui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
		fbui.start('#firebase-ui-auth-container', this.fbuiConfig);
	},
	methods: {
		async logout() {
			await firebase.auth().signOut();
		}
	}
});
