<template>
	<q-layout view="lHh Lpr lFf">
		<q-header elevated>
			<q-toolbar color="dark">

				<q-toolbar-title>
					<router-link to="/">Crewtracks ITAD</router-link>
				</q-toolbar-title>

				<div><router-link to="/user">{{ $store.state.user ? $store.state.user.displayName : 'Not Logged In' }}</router-link></div>
			</q-toolbar>
		</q-header>

		<q-page-container>
			<router-view :key="$route.fullPath"></router-view>
		</q-page-container>
	</q-layout>
</template>

<style lang="scss">
.q-toolbar {
	a {
		color: white;
		text-decoration: none;
	}
}
</style>

<script>

export default {
	name: 'LayoutDefault',

	components: {
	},

	data () {
		return {
		}
	}
}
</script>
